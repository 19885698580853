<template>
  <div>
    <v-row>
      <v-col
        md="8"
        offset-md="2"
      >
        <v-card>
          <v-card-title>New Departure</v-card-title>
          <v-card-text>
            <departure-form
              :on-success="onSuccess"
              :show-terms="false"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DepartureForm from "../../../components/DepartureForm";

export default {
  components: {
    DepartureForm
  },

  methods: {
    async onSuccess(formData) {
      try {
        await this.$store.dispatch("departures/new", formData);
        this.$router.replace("/admin/departures/list");
      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>
